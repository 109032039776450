import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import { FormContext } from "./context/FormProvider";
import useCurrentProject from "./FormQueries/useCurrentProject";
import useSetFormStatus from "./FormQueries/useSetFormStatus";
import { verifySubmission } from "./Helper/formbtnhelper.js";
import {
  getAllSlidesStatus,
  populateFormsState,
  setReviseStatusPageNumber,
  updateFormSlidesStatus,
} from "./main-helper";
import Slides from "./Slides";
import useInvalidInputs from "./Slides/Hooks/useInvalidInputs";
import "./styles/form-container.css";
import NewTimeline from "./Timeline/NewTimeline.jsx";

const FormContainer = () => {
  // Shared Context From Dashboard
  const {
    isAdmin,
    setHeading,
    navigate,
    projectId,
    clientId,
    userId,
    queryClient,
  } = useContext(DashboardContext);

  const { enqueueSnackbar } = useSnackbar();

  const [width, setWidth] = useState(window.innerWidth || 0);

  window.addEventListener("resize", () => {
    setWidth(() => window.innerWidth);
  });

  useEffect(() => setHeading("Questionnaire Portal"), []);

  // Form Specific Context
  const {
    slide1,
    setSlide1,
    slide2,
    setSlide2,
    slide3,
    setSlide3,
    slide4,
    setSlide4,
    slide5,
    setSlide5,
    slide6,
    setSlide6,
    slide7,
    setSlide7,
    slide8,
    setSlide8,
    slide9,
    setSlide9,
    slide10,
    setSlide10,
    slide11,
    setSlide11,
    setTimeStamp,
    isLoading,
    pageNumber,
    setPageNumber,
    setDisableAll,
    requestStatus,
    setRequestStatus,
    activeUserId,
    setActiveUserId,
    openCheckModal,
    setOpenCheckModal,
    setIsReady,
    setRequiredFields,
  } = useContext(FormContext);
  /*
  ~ Custom hook that fetches the current request/project data from the server
  ~ View: ProjectFormStateAPIView
  ~ GETs activeUserId, requestStatus, timeStamp, and each slide's data stored in their own objects
  */
  const {
    data: currentProjectData,
    isError: isCurrentProjectError,
    error: currentProjectError,
    isSuccess: currentProjectSuccess,
    isLoading: currentProjectLoading,
    isFetched: currentProjectFetched,
    isFetching: currentProjectFetching,
  } = useCurrentProject(clientId, projectId);

  useEffect(() => {
    if (
      !currentProjectFetching &&
      currentProjectData &&
      !isLoading &&
      currentProjectData.timeStamp
    ) {
      setTimeStamp(() => currentProjectData.timeStamp);
    }
  }, [currentProjectFetching, isLoading]);

  /*
  ~ Custom mutation hook that updates the request/project status in the server specific to the active
  ~ View: UpdateProjectRequestStatus
  ~ Returns: requestStatus & activeUserId
  */
  const updateFormStatus = useSetFormStatus(
    clientId,
    projectId,
    setRequestStatus,
    setActiveUserId,
  );

  /*
  ~ Custom hook that fetches the invalid inputs for the current project
  ~ View: InvalidInputsAPIView
  ~ Only used when the requestStatus is "RV"
  */

  useEffect(() => {
    const handleBeforeUnload = async (e) => {
      e.preventDefault();
      e.returnValue = true;

      if (
        currentProjectData &&
        currentProjectData.activeUserId === userId &&
        requestStatus === "IP"
      ) {
        await updateFormStatus.mutateAsync("I", userId);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const { data: invalidInputsData, isFetched: invalidInputsFetched } =
    useInvalidInputs(clientId, projectId, requestStatus);

  // Each slides progress status (used for timeline/color legend)
  const [slideStatus, setSlideStatus] = useState({
    slide1: "incomplete",
    slide2: "incomplete",
    slide3: "incomplete",
    slide4: "incomplete",
    slide5: "incomplete",
    slide6: "incomplete",
    slide7: "incomplete",
    slide8: "incomplete",
    slide9: "complete",
    slide10: "incomplete",
    slide11: "complete",
  });

  // Array of slide/page numbers that contain an invalid input
  const [invalidSlideNumbers, setInvalidSlideNumbers] = useState([]);

  /*
  ~ useEffects to handle the window closing
  ~ If the currentProjectData.requestStatus is "IP", set the requestStatus to "I"
  */
  // On initial mount, if path contains dashboard/form/:clientID/:projectID/:slideNumber, set the pageNumber to the slideNumber & update each slides state
  useEffect(() => {
    if (!projectId || !clientId) return;
    if (pageNumber === 0) {
      const path = window.location.pathname.split("/");
      const slideNumber = path[path.length - 1];
      setPageNumber(() => +slideNumber);
    }
  }, []);

  // ? Change this useEffect so that it only sets disableAll to false if the requestStatus is "Not Started"
  useEffect(() => {
    if (!currentProjectData || requestStatus === "Not Started") return;
    if (requestStatus === "IP" && activeUserId !== userId) {
      setDisableAll(() => true);
    } else if (
      (requestStatus === "IP" && activeUserId === userId) ||
      (requestStatus === "I" && activeUserId === null)
    ) {
      setDisableAll(() => false);
    } else if (requestStatus === "Not Started") {
      setDisableAll(() => false);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (!currentProjectFetched || !currentProjectData) return;

    updateFormSlidesStatus(
      clientId,
      projectId,
      userId,
      currentProjectData,
      pageNumber,
      requestStatus,
      setRequestStatus,
      updateFormStatus,
      setDisableAll,
      activeUserId,
      setActiveUserId,
      queryClient,
      navigate,
    );
  }, [
    clientId,
    projectId,
    currentProjectFetched,
    isCurrentProjectError,
    pageNumber,
  ]);

  useEffect(() => {
    if (!projectId || !clientId) {
      setPageNumber(() => 0);
      return;
    }
    let slides = [
      slide1,
      slide2,
      slide3,
      slide4,
      slide5,
      slide6,
      slide7,
      slide8,
      slide9,
      slide10,
      slide11,
    ];
    let setSlides = [
      setSlide1,
      setSlide2,
      setSlide3,
      setSlide4,
      setSlide5,
      setSlide6,
      setSlide7,
      setSlide8,
      setSlide9,
      setSlide10,
      setSlide11,
    ];

    if (currentProjectFetched && currentProjectData) {
      if (currentProjectData.requestStatus !== "C") {
        populateFormsState(
          clientId,
          projectId,
          currentProjectData,
          slides,
          setSlides,
          setTimeStamp,
          pageNumber,
          setPageNumber,
          navigate,
        );
      } else {
        if (invalidInputsFetched && invalidInputsData) {
          setReviseStatusPageNumber(
            invalidSlideNumbers,
            invalidInputsData,
            currentProjectData,
            pageNumber,
            setPageNumber,
            navigate,
          );
        }
      }
      setPageNumber(() => +currentProjectData.recentSlide || 1);
    }
  }, []);

  useEffect(() => {
    if (!projectId || pageNumber === 0) return;
    getAllSlidesStatus(
      {
        slide1,
        slide2,
        slide3,
        slide4,
        slide5,
        slide6,
        slide7,
        slide8,
        slide9,
        slide10,
        slide11,
      },
      setSlideStatus,
      currentProjectData,
      userId,
    );
  }, [
    currentProjectData,
    pageNumber,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
  ]);

  const cancelSubmit = (e) => {
    setIsReady(false);
    setOpenCheckModal(false);
  };

  return (
    <Container
      className={
        width > 1275 && projectId !== null && pageNumber >= 1
          ? ""
          : "hide-timeline"
      }
      id="main-form-container"
      style={{
        gridTemplateAreas:
          width > 1275 && projectId !== null && pageNumber >= 1
            ? "'timeline-container form-container-col'"
            : "'form-container-col'",
        gridTemplateColumns:
          width > 1275 && projectId !== null && pageNumber >= 1
            ? "1fr 4fr"
            : "1fr",
      }}
    >
      <NewTimeline
        slideStatus={slideStatus}
        invalidSlides={invalidSlideNumbers}
        setInvalidSlideNumbers={setInvalidSlideNumbers}
      />
      <div className="form-container-col">
        <Slides
          slideStatus={slideStatus}
          setSlideStatus={setSlideStatus}
          formState={currentProjectData}
          formStateLoaded={currentProjectFetched}
        />
      </div>
      <Modal
        id="submit-check-modal"
        classes={{
          root: "submit-check-modal",
          paper: "submit-check-modal-paper",
        }}
        open={openCheckModal}
        onClose={() => {
          setOpenCheckModal(false);
          setIsReady(false);
        }}
        aria-labelledby="submit-check-modal"
        aria-describedby="submit-check-modal-description"
      >
        <div className="submit-check-modal-content">
          <header id="check-header">
            <h1 id="submit-check-header">Ready to Submit?</h1>
            <hr id="submit-form-check-hr" />
          </header>
          <div id="check-content">
            <p id="submit-check-message">
              {requestStatus === "IP" || requestStatus == "I" ? (
                <>
                  A successful submission attempt will make the form uneditable,
                  unless revisions are requested by Argus. If you are not ready
                  to submit the form, you may close or exit this page and submit
                  at a later time.
                </>
              ) : (
                <>
                  Resubmitting the form will make the form uneditable, unless
                  new revisions are requested by Argus. If you are not ready to
                  submit the form, you may close or exit this page and submit at
                  a later time.
                </>
              )}
            </p>
            <div className="modal-btn-container">
              <Button
                id="close-modal-btn"
                size="small"
                variant="contained"
                onClick={cancelSubmit}
              >
                Close
              </Button>
              <Button
                id="submit-modal-btn"
                size="small"
                variant="contained"
                onClick={(e) => {
                  let verified = verifySubmission(slideStatus);
                  if (verified === true || requestStatus === "RV") {
                    setOpenCheckModal(false);
                    setIsReady(true);
                  } else {
                    enqueueSnackbar(
                      "There are some required fields that have not been filled out. Please fill out all required fields before submitting. Incomplete slides will have a grey or yellow dot on the navigation hub to the left. Required fields are marked with a red outline on each slide.",
                      {
                        variant: "warning",
                      },
                    );
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

export default FormContainer;
