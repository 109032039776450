import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import Upload from "@mui/icons-material/Upload";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import { useIsMutating } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider.jsx";
import { FormContext } from "../context/FormProvider.jsx";
import * as Messages from "../messages/messages.js";
import { useDeleteFile, useUploadFiles } from "../Slides/Hooks/fileQueries.jsx";
import "./styles/attach-file-field.css";
import "./styles/file-field.css";
import TextField from "./TextField.jsx";

/**
 * FileStatusField Component
 * @description A component that handles updating the attached status for all file inputs.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the file field.
 * @param {string} props.idProp - The id of the file field.
 * @param {Object} props.state - The state object.
 * @param {Function} props.setState - The state setter function.
 * @param {string} props.val - The value of the file field.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @returns {JSX.Element} The rendered FileStatusField component.
 */
const FileStatusField = ({
  label,
  idProp,
  state,
  setState,
  val,
  debouncedSave,
  disabledInput,
}) => {
  const [open, setOpen] = useState(false);
  // Defining the checkboxes checked value based on the value provided
  const isAttached = val === "AT";
  const isNotAvailable = val === "NAV";
  const isNotApplicable = val === "NA";

  // Get the number of mutations in progress, used to disable all inputs to prevent mishandling of files
  const isMutating = useIsMutating();

  // Context
  const { setIsLoading, handleInputDisable, disableAll } =
    useContext(FormContext);
  const { userId } = useContext(DashboardContext);

  // Function to handle the radio group change
  const handleRadioChange = (e) => {
    const newStatus = e.target.value;
    const currentStatus = state[idProp].status;
    // Only update the state if the status has changed
    if (currentStatus !== newStatus) {
      const newState = {
        ...state,
        [idProp]: {
          ...state[idProp],
          status: newStatus,
          ...(newStatus !== "AT" && { files: [] }), // Clear files if not "AT"
        },
        ...(idProp === "dustPermit" &&
          newStatus !== "AT" && { dustPermitNumber: "" }), // Clear dustPermitNumber if needed
      };

      // Update the state and set loading to true
      setState(newState);
      setIsLoading(true);

      // Map state to the backend payload format
      const uploadState = Object.entries(newState).map(([key, value]) => {
        if (key === "dustPermitNumber") {
          return { name: key, dustPermitNumber: value }; // Handle dustPermitNumber separately
        }
        return { name: value.name, status: value.status }; // Default case for files
      });
      // Save the updated state
      debouncedSave.mutate(uploadState);
    }
  };

  return (
    <div id="input-attach-container">
      <label
        className="attach-form-file-label"
        id={`${idProp}-attach-label`}
        htmlFor="attach-file-radio-group"
      >
        {label}:
      </label>
      <RadioGroup
        aria-label="attach-file-radio-group"
        defaultValue="Unconfirmed"
        name="radio-buttons-group"
        id="attach-file-radio-group"
        classes={{
          root: "attach-file-radio-group-root",
          row: "attach-file-radio-group-row",
        }}
        onChange={handleRadioChange}
        value={state[idProp].status}
      >
        <FormControlLabel
          value="AT"
          id="attach-form-control"
          label="Attach"
          control={
            <Radio
              classes={{
                root: "attached-radio-root",
                checked: "attached-radio-checked",
              }}
              checked={isAttached}
              disabled={
                disableAll ||
                handleInputDisable(idProp, state, disabledInput, userId) ||
                isMutating > 0
              }
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <FormControlLabel
          value="NAV"
          id="attach-form-control"
          label="Not Available"
          control={
            <Radio
              classes={{
                root: "not-available-radio-root",
                checked: "not-available-radio-checked",
              }}
              checked={isNotAvailable}
              disabled={
                disableAll ||
                handleInputDisable(idProp, state, disabledInput, userId)
              }
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <FormControlLabel
          value="NA"
          id="attach-form-control"
          label="N/A"
          control={
            <Radio
              classes={{
                root: "not-applicable-radio-root",
                checked: "not-applicable-radio-checked",
              }}
              label="N/A"
              checked={isNotApplicable}
              disabled={handleInputDisable(
                idProp,
                state,
                disabledInput,
                userId,
              )}
              icon={
                <SvgIcon
                  component={CheckBoxOutlineBlank}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckBox}
                  className="attach-checkbox-icon"
                  viewBox="3 3 18 18"
                />
              }
            />
          }
          classes={{
            root: "attach-form-control-root",
            label: "attach-btn-file-label",
          }}
          labelPlacement="end"
        />
        <Tooltip
          id="attach-tooltip"
          classes={{
            tooltip: "attach-file-tooltip",
            popper: "attach-popper",
            arrow: "attach-arrow",
            tooltipPlacementRight: "attach-right",
          }}
          title={Messages[`${idProp}Message`]}
          placement="right"
          arrow
          open={open}
        >
          <InfoCircle
            className="text-info-circ file-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
      </RadioGroup>
    </div>
  );
};

const CustomUploadProgress = () => {
  return (
    <Box id="uploadProgressContainer">
      <Typography id="uploadProgressText" variant="h6">
        {/* {name} */}
        Uploading Files
      </Typography>
      <Box
        id="uploadProgress"
        sx={{ position: "relative", display: "inline-flex" }}
      >
        <CircularProgress
          id="uploadFileProgress"
          // variant="determinate"
          // value={progress}
          classes={{
            svg: "uploadFileProgressSvg",
          }}
        />
      </Box>
    </Box>
  );
};

const UploadFileField = ({
  idProp,
  state,
  setState,
  disabledInput,
  isUploading,
  setIsUploading,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadProgress, setUploadProgress] = useState({ [idProp]: false });

  const { setIsLoading, handleInputDisable, disableAll } =
    useContext(FormContext);

  const { userId, clientId, projectId, queryClient } =
    useContext(DashboardContext);

  const deleteFile = useDeleteFile(
    clientId,
    projectId,
    idProp,
    state,
    setState,
    enqueueSnackbar,
  );
  const uploadFormFiles = useUploadFiles(setIsUploading, setState);

  const uploadFileHandler = async (files) => {
    try {
      const uploadedFiles = await uploadFormFiles.mutateAsync({
        clientId,
        projectId,
        idProp,
        files,
        userId,
      });

      const allUploadedFiles = uploadedFiles
        .map((file) => file.data.files)
        .flat();

      setState((prevState) => ({
        ...prevState,
        [idProp]: {
          ...prevState[idProp],
          files: [...prevState[idProp].files, ...allUploadedFiles],
        },
      }));

      enqueueSnackbar(`All files uploaded successfully.`, {
        variant: "success",
        autoHideDuration: 2500,
      });
    } catch (err) {
      enqueueSnackbar(`Error uploading files. ${err.message}`, {
        variant: "error",
        autoHideDuration: 2500,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = Array.from(e.dataTransfer.files);

    if (uploadedFiles.length === 0) return;

    if (uploadedFiles.length > 5) {
      enqueueSnackbar("You can only upload up to 5 files at a time.", {
        variant: "warning",
        autoHideDuration: 2500,
      });
    }

    setIsUploading(true);
    setUploadProgress({ [idProp]: true });
    setIsLoading(true);

    await uploadFileHandler(uploadedFiles);

    setIsUploading(false);
    setUploadProgress({ [idProp]: false });
    setIsLoading(false);
  };

  const handleFileSelect = async (e) => {
    const uploadedFiles = Array.from(e.target.files);

    if (uploadedFiles.length === 0) return;

    if (uploadedFiles.length > 5) {
      enqueueSnackbar("You can only upload up to 5 files at a time.", {
        variant: "warning",
        autoHideDuration: 2500,
      });
    }

    setIsUploading(true);
    setUploadProgress({ [idProp]: true });
    setIsLoading(true);

    await uploadFileHandler(uploadedFiles);

    setIsUploading(false);
    setUploadProgress({ [idProp]: false });
    setIsLoading(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrageEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Container id="uploadFilesContainer">
      <Box
        id="dragDropContainer"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDrageEnter}
        onDragLeave={handleDragLeave}
        onClick={() => {
          document.getElementById("hidden-input").click();
        }}
        disabled={
          isUploading ||
          disableAll ||
          handleInputDisable(idProp, state, disabledInput, userId)
        }
        style={{
          cursor:
            isUploading ||
            disableAll ||
            handleInputDisable(idProp, state, disabledInput, userId)
              ? "not-allowed"
              : "pointer",
          pointerEvents:
            isUploading ||
            disableAll ||
            handleInputDisable(idProp, state, disabledInput, userId)
              ? "none"
              : "auto",
        }}
      >
        {(!isUploading || !uploadProgress[idProp]) && (
          <Upload className="uploadFileIcon" id="uploadFileIcon" />
        )}
        {(!isUploading || !uploadProgress[idProp]) && (
          <Typography id="dragDropText" variant="h6">
            Click to choose a file, or drag and drop files here
          </Typography>
        )}
        {isUploading && uploadProgress[idProp] && <CustomUploadProgress />}

        <input
          id="hidden-input"
          type="file"
          hidden
          multiple
          onChange={handleFileSelect}
          disabled={
            isUploading ||
            disableAll ||
            handleInputDisable(idProp, state, disabledInput, userId)
          }
        />
      </Box>
      {state[idProp].files.length > 0 && (
        <Box id="uploadedFiles">
          <Typography id="uploadedFilesHeader" variant="h6"></Typography>
          <Box id="uploadedFilesList">
            {state[idProp].files.map((file, index) => (
              <Box className="file-status-box" key={file.id}>
                <Typography
                  className="uploaded-file-name"
                  onClick={(e) => {
                    // Open file.file in new tab
                    window.open(file.file, "_blank");
                  }}
                >
                  {file.fileName.length <= 28
                    ? file.fileName
                    : `${file.fileName.slice(0, 25)}...`}
                </Typography>
                <SvgIcon
                  component={ClearIcon}
                  className="delete-file-icon"
                  viewBox="3 3 18 18"
                  onClick={async (e) => {
                    await deleteFile.mutateAsync({
                      uploadId: file.id,
                    });
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Container>
  );
};

const FileField = ({
  idProp,
  label,
  state,
  setState,
  disabledInput,
  setIsLoading,
  isUploading,
  setIsUploading,
  val,
  debouncedSave,
  invalidData = null,
  disabledDustPermitNumber = false,
}) => {
  return (
    <div className="attach-file-container">
      <FileStatusField
        label={label}
        idProp={idProp}
        state={state}
        setState={setState}
        val={val}
        debouncedSave={debouncedSave}
        disabledInput={disabledInput}
      />
      {state[idProp].status === "AT" && (
        <UploadFileField
          idProp={idProp}
          state={state}
          setState={setState}
          disabledInput={disabledInput}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      )}
      {idProp === "dustPermit" && state[idProp].status === "AT" && (
        <TextField
          idProp="dustPermitNumber"
          label="Dust Permit Number"
          state={state}
          setState={setState}
          val={state.dustPermitNumber}
          debouncedSave={debouncedSave}
          setIsLoading={setIsLoading}
          disabledInput={disabledDustPermitNumber}
          disabledInputMessage={""}
          invalidData={invalidData}
        />
      )}
    </div>
  );
};

export default FileField;
