// NEXT BUTTON HANDLER (Used in timeline & form btns)
/**
 * @description This function is used to handle the next button in the form. The function also handles creating the form. It is used in the timeline and form buttons.
 * @param {Event} e - The event object.
 * @param {string} clientId - The client ID.
 * @param {string} currentProjectID - The current project ID.
 * @param {Object} slide1 - The slide1 object.
 * @param {number} pageNumber - The current page number.
 * @param {function} setPageNumber - The function to set the page number.
 * @param {number} newPageNumber - The new page number.
 * @param {Object} createForm - The createForm object.
 * @param {Object} queryClient - The queryClient object.
 */
export async function handleNext(
  e,
  clientId,
  currentProjectID,
  slide1,
  pageNumber,
  setPageNumber,
  newPageNumber,
  createForm,
  queryClient,
) {
  e.preventDefault();
  // if the page number is 1 and there is no current project ID, create the form
  if (pageNumber === 1 && !currentProjectID) {
    await createForm.mutateAsync(slide1);
  } else if (pageNumber > 0 && currentProjectID) {
    // if the page number is greater than 0 and there is a current project ID, invalidate the predictiveText query
    queryClient.invalidateQueries({
      queryKey: ["predictiveText", clientId],
    });
    // Update the page number
    setPageNumber(+newPageNumber);
  } else if (pageNumber === 0) {
    // if the page number is 0, update the page number
    setPageNumber(+newPageNumber);
  }
}

// Verify that the email matched proper formatting
export const isValidEmail = (email, optional = true) => {
  if (optional === true && email === "") return true;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

// Verify that the name matched proper formatting
export const isValidName = (name, optional = true) => {
  // must include at least first and last name separated by a space & can include a hyphen (-)
  if (optional === true && name === "") return true;

  let nameArray = name.split(" ");

  return nameArray.length >= 2 && nameArray[1] !== "";
};

// Verify that the phone number matched proper formatting
export const isValidPhoneNumber = (phone, optional = true) => {
  // must be exactly 12 digits long (dashes are dynamically added) (###-###-####)
  if (optional === true && phone.length === 0) return true;
  if (optional === false && phone.length === 0) return false;
  const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
  return phonePattern.test(phone);
};

// Verify that the form is ready to be submitted
export const verifySubmission = (slideStatus) => {
  // if all slides except slide9 have a value equal === 'complete' then return true
  // slideStatus is an object with keys 'slide1', 'slide2', up to 'slide10' and the values can be 'complete', or 'incomplete'
  let verified = false;
  for (let slide in slideStatus) {
    if (
      slide === "slide10" &&
      slideStatus["slide1"] === "complete" &&
      slideStatus["slide2"] === "complete" &&
      slideStatus["slide3"] === "complete" &&
      slideStatus["slide4"] === "complete" &&
      slideStatus["slide5"] === "complete" &&
      slideStatus["slide6"] === "complete" &&
      slideStatus["slide7"] === "complete" &&
      slideStatus["slide8"] === "complete"
    ) {
      verified = true;
      continue;
    } else if (
      slide === "slide10" &&
      (slideStatus["slide1"] === "incomplete" ||
        slideStatus["slide2"] === "incomplete" ||
        slideStatus["slide3"] === "incomplete" ||
        slideStatus["slide4"] === "incomplete" ||
        slideStatus["slide5"] === "incomplete" ||
        slideStatus["slide6"] === "incomplete" ||
        slideStatus["slide7"] === "incomplete" ||
        slideStatus["slide8"] === "incomplete")
    ) {
      verified = false;
      break;
    } else {
      verified = true;
    }
  }
  return verified;
};
